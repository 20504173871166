import request from '@/utils/request.js'

// 会员标签管理
export function getCustomertagIndex(query) {
	return request({
		url: '/api/manage/customer/tagindex',
		method: 'get',
		params: {
			...query
		}
	})
}
// 创建会员标签
export function customerTagCreateApi(query) {
	return request({
		url: '/api/manage/customer/tagcreate',
		method: 'post',
		data: {
			...query
		}
	})
}
// 会员标签详情
export function customerTagDetailApi(id) {
	return request({
		url: '/api/manage/customer/tagdetail',
		method: 'get',
		params: {
			id
		}
	})
}
// 修改
export function customerTagEditApi(query) {
	return request({
		url: '/api/manage/customer/tagedit',
		method: 'post',
		data: {
			...query
		}
	})
}
// 批量删除 
export function customerTagBatchDelApi(ids) {
	return request({
		url: '/api/manage/customer/tagBatchDel',
		method: 'post',
		data: {
			ids
		}
	})
}
// 
export function getCustomerDataConsumeApi() {
	return request({
		url: '/api/manage/customerdata/consume',
		method: 'get',
	})
}
// 获取新增会员数(昨天/今天)
export function getCustomerDataApi() {
	return request({
		url: '/api/manage/customerdata/new',
		method: 'get',
	})
}
// 获取消费会员数
export function getAllConsumeApi() {
	return request({
		url: '/api/manage/customerdata/allconsume',
		method: 'get',
	})
}
// 获取近X日新增会员数
export function getNewgroupBydateApi() {
	return request({
		url: '/api/manage/customerdata/newgroupbydate',
		method: 'get',
	})
}
// 获取近X日消费会员数
export function getConsumegroupBydateApi() {
	return request({
		url: '/api/manage/customerdata/consumegroupbydate',
		method: 'get',
	})
}
// 会员列表
export function getCustomerIndex(query) {
	return request({
		url: '/api/manage/customer/index',
		method: 'get',
		params: {
			...query
		}
	})
}
// 创建会员
export function customerCreateApi(query) {
	return request({
		url: '/api/manage/customer/create',
		method: 'post',
		data: {
			...query
		}
	})
}
// 设为黑名单
export function customerBlackApi(id,is_black) {
	return request({
		url: '/api/manage/customer/black',
		method: 'post',
		data: {
			id,is_black
		}
	})
}
// 设置会员标签
export function customerTagApi(id,tag_id) {
	return request({
		url: '/api/manage/customer/tag',
		method: 'post',
		data: {
			id,tag_id
		}
	})
}
// 批量设置
export function customerBatchTagApi(ids,tag_id) {
	return request({
		url: '/api/manage/customer/batchTag',
		method: 'post',
		data: {
			ids,tag_id
		}
	})
}
// 编辑会员
export function customerEditApi(query) {
	return request({
		url: '/api/manage/customer/edit',
		method: 'post',
		data: {
			...query
		}
	})
}
// 调整会员积分
export function customerAdjustScoreApi(query) {
	return request({
		url: '/api/manage/customer/adjustScore',
		method: 'post',
		data: {
			...query
		}
	})
}
// 会员详情
export function getCustomerDetailApi(id) {
	return request({
		url: '/api/manage/customer/detail',
		method: 'get',
		params: {
			id
		}
	})
}
// 积分列表
export function getCustomerScoreListApi(query) {
	return request({
		url: '/api/manage/customer/scoreList',
		method: 'get',
		params: {
			...query
		}
	})
}
// 订单列表
export function getCustomerOrderListApi(query) {
	return request({
		url: '/api/manage/customer/orderList',
		method: 'get',
		params: {
			...query
		}
	})
}
// 浏览记录
export function getCustomerBrowseProductApi(query) {
	return request({
		url: '/api/manage/customer/browseProduct',
		method: 'get',
		params: {
			...query
		}
	})
}
// 收藏记录
export function getCustomerLoveProductApi(query) {
	return request({
		url: '/api/manage/customer/loveProduct',
		method: 'get',
		params: {
			...query
		}
	})
}
// 会员地址列表
export function getCustomeraddressApi(query) {
	return request({
		url: '/api/manage/customer/address',
		method: 'get',
		params: {
			...query
		}
	})
}
// 会员等级列表
export function getCustomerlevelIndexApi(query) {
	return request({
		url: '/api/manage/customerlevel/index',
		method: 'get',
		params: {
			...query
		}
	})
}
// 编辑会员等级
export function customerlevelEditApi(query) {
	return request({
		url: '/api/manage/customerlevel/edit',
		method: 'post',
		data: {
			...query
		}
	})
}
// 新增会员等级
export function customerlevelCreateApi(query) {
	return request({
		url: '/api/manage/customerlevel/create',
		method: 'post',
		data: {
			...query
		}
	})
}
// 会员等级详情
export function getCustomerlevelDetailApi(id) {
	return request({
		url: '/api/manage/customerlevel/detail',
		method: 'get',
		params: {
			id
		}
	})
}
// 获取全部会员等级
export function getCustomerlevelAllApi() {
	return request({
		url: '/api/manage/customerlevel/all',
		method: 'get',
	})
}