import axios from 'axios'
import {
	message
} from 'ant-design-vue';
import {
		useRouter,
		useRoute
	} from 'vue-router';
const service = axios.create({
	// baseURL: process.env.VUE_APP_BASE_API,
	// timeout: 5000
})

// 请求拦截器
service.interceptors.request.use(config => {
		const token = localStorage.getItem('token');
		config.headers['source'] = 5;
		if (token) {
			config.headers.Authorization = `Bearer ${token}`
		}
		if (config.url == "/api/manage/upload") {
			config.headers['Content-Type'] = 'multipart/form-data';
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

// 响应拦截器
service.interceptors.response.use(response => {
		const {
			code
		} = response.data
		if (code !== 200) {
			message.error(response.data.message)
			return Promise.reject(new Error(response.data.message))
		} else {
			return response.data
		}
	},
	// 失败
	error => {
		if(error.response.status == 401){
			message.error('请重新登录').then(()=>{
				location.href = '/';
				return Promise.reject(error)
			});
			
		}else{
			message.error('系统错误，详情请联系工作人员')
		}
		return Promise.reject(new Error(error.response))
	}
)

export default service
