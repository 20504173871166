import request from '@/utils/request.js'

// 获取订单配置
export function getOrderConfigApi() {
	return request({
		url: '/api/manage/order/config',
		method: 'get'
	})
}
// 设置订单配置
export function postOrderSetconfigApi(data) {
	return request({
		url: '/api/manage/order/setconfig',
		method: 'post',
		data:{
			...data
		}
		
	})
}
// 获取评价配置信息
export function getEvaluateConfigApi() {
	return request({
		url: '/api/manage/evaluate/config',
		method: 'get'
	})
}
// 设置评价配置
export function postEvaluateSetconfigApi(data) {
	return request({
		url: '/api/manage/evaluate/setconfig',
		method: 'post',
		data:{
			...data
		}
		
	})
}