import { createApp } from 'vue'
import deleteCom from '@/components/delete/delete'
import App from './App.vue' // App
import router from './router' // 路由
// antd design
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

// lang
import i18n from './lang/index.js'

// pinia
import pinia from '@/pinia/index.js'

import * as Icons from '@ant-design/icons-vue'
// 在main.js先引入ant的提醒方法
import { message } from 'ant-design-vue';
import VueParticles from 'vue-particles'
 
let app = createApp(App)
// 在定义app的原型上用provide进行挂载
app.provide("message", message);
app.component('deleteCom',deleteCom);
Object.keys(Icons).map(key => {
  app.component(key, Icons[key])
})

app.use(VueParticles).use(pinia).use(Antd).use(router).use(i18n).mount('#app')
