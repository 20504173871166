import request from '@/utils/request.js'
/* 首页 */
export function getOrderMonthApi() {
	return request({
		url: '/api/manage/order/month',
		method: 'get',
	})
}
export function getOrderStatisticApi() {
	return request({
		url: '/api/manage/order/statistic',
		method: 'get',
	})
}
// 获取订单配置
export function getOrderIndexApi(params) {
	return request({
		url: '/api/manage/order/index',
		method: 'get',
		params:{
			...params
		}
	})
}
// 修改订单地址
export function postOrderAddressApi(params) {
	return request({
		url: '/api/manage/order/address',
		method: 'post',
		data:{
			...params
		}
	})
}
// 修改订单备注
export function postOrderRemarkApi(params) {
	return request({
		url: '/api/manage/order/remark',
		method: 'post',
		data:{
			...params
		}
	})
}
// 获取订单详情
export function getOrderDetailApi(id) {
	return request({
		url: '/api/manage/order/detail',
		method: 'get',
		params:{
			id
		}
	})
}
// 发货
export function getOrderSendApi(data) {
	return request({
		url: '/api/manage/order/send',
		method: 'post',
		data:{
			...data
		}
	})
}
// 维权订单列表
export function getOrderRefundListApi(params) {
	return request({
		url: '/api/manage/order/refundList',
		method: 'get',
		params:{
			...params
		}
	})
}
// 维权订单详情
export function getOrderRefundDetailApi(id) {
	return request({
		url: '/api/manage/order/refundDetail',
		method: 'get',
		params:{
			id
		}
	})
}
// 修改订单物流信息
export function postOrderExpressApi(data) {
	return request({
		url: '/api/manage/order/express',
		method: 'post',
		data:{
			...data
		}
	})
}
// 审核维权申请
export function postOrderAuditRefundApi(data) {
	return request({
		url: '/api/manage/order/auditRefund',
		method: 'post',
		data:{
			...data
		}
	})
}
// 确认维权收货
export function getOrderSureRefundApi(id) {
	return request({
		url: '/api/manage/order/sureRefund',
		method: 'get',
		params:{
			id
		}
	})
}
// 维权转账
export function getOrderTransferApi(id) {
	return request({
		url: '/api/manage/order/transfer',
		method: 'post',
		params:{
			id
		}
	})
}
// 查看物流信息
export function getExpressQueryApi(params) {
	console.log("params",params)
	return request({
		url: '/api/manage/express/query',
		method: 'get',
		params
	})
}
// 发票列表
export function getInvoiceIndexApi(params) {
	console.log("params",params)
	return request({
		url: '/api/manage/invoice/index',
		method: 'get',
		params
	})
}
// 发票详情
export function getInvoiceDetailApi(params) {
	console.log("params",params)
	return request({
		url: '/api/manage/invoice/detail',
		method: 'get',
		params
	})
}
export function getInfoTwoApi(params) {
	console.log("params",params)
	return request({
		url: '/app/common/infoTwo',
		method: 'GET',
		params: {
			...params
		}
	})
}