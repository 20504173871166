// 小仓库 组合式
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
// import getMenu from '@/static/menu.js'


let Menu = defineStore('menu', () => {
  let MenuList = ref(JSON.parse(sessionStorage.getItem('menu')) || []) // 菜单列表
  let SecondaryMenu = ref([]) // 二级菜单列表

  // 获取菜单
  const GetMenuFn = (val) => {
    sessionStorage.setItem("menu", JSON.stringify(val)); // 持久化
    MenuList.value = JSON.parse(sessionStorage.getItem("menu"))
  }

  // 获取二级菜单
  const GetSecondaryMenu = (e) => {
    SecondaryMenu.value = MenuList.value.filter(item => item.id == e)
  }

  return {
    MenuList,
    SecondaryMenu,
    GetMenuFn,
    GetSecondaryMenu
  }
})

export default Menu