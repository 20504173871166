import { createI18n } from 'vue-i18n';
import zh from './zh-CN';
import en from './en';

const messages = {
  en,
  zh
};

const i18n = createI18n({
  legacy: false, // 防止报错
  locale: localStorage.getItem('lang') || 'zh', // 首先从缓存里拿，没有的话就默认中文，
  fallbackLocale: 'zh', // 设置备用语言
  messages
});

export default i18n;