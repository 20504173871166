// 动态路由
import router from './index.js'
import pinia from '@/pinia/index'
import Menu from '../pinia/menu/index.js'

let menu = Menu(pinia)

// 路由添加
const RouteAddition = (router) => {
  menu.MenuList.forEach((route) => {
    if (route.children && route.children.length > 0) {
      route.children.forEach(child => {
        const dynamicRoutes = reconfiguration(child)
        router.addRoute('DisplayPage', dynamicRoutes);
      })
    } else {
      const dynamicRoutes = reconfiguration(route)
      router.addRoute('DisplayPage', dynamicRoutes);
    }
  });
}

const reconfiguration = (item) => {
  var route = {
    path: item.url,
    name: item.url.split('/')[1],
    meta: item.meta || { tite: item.name },
    component: item.component ? () => import(`@/views/${item.component}`) : () => import("@/views/error/404"),
    children: []
  }
  if (item.children && item.children.length > 0) {
    item.children.forEach((child) => {
      route.children.push(reconfiguration(child));
    })
  }
  return route
}

export default RouteAddition


