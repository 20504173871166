import request from '@/utils/request.js'

// 商城地址列表
export function getShopAddressIndexApi(query) {
	return request({
		url: '/api/manage/config/shopaddress/index',
		method: 'get',
		params: query
	})
}
// 商城地址添加
export function postShopAddressCreateApi(data) {
	return request({
		url: '/api/manage/config/shopaddress/create',
		method: 'post',
		data:{
			...data
		}
	})
}
// 商城地址编辑
export function postShopAddressEditApi(data) {
	return request({
		url: '/api/manage/config/shopaddress/edit',
		method: 'post',
		data:{
			...data
		}
	})
}
// 详情
export function getShopAddressDetailApi(id) {
	return request({
		url: '/api/manage/config/shopaddress/detail',
		method: 'get',
		params: {
			id
		}
	})
}