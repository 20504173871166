// zh
export default {
  // 登陆页面
  login: {
    'BackgroundLogin': '后台登录',
    'logon': '登录',
    'username': '用户名',
    'password': '密码',
    'code': '验证码',
    'UserNamePlaceholder': '请输入用户名',
    'PassWordPlaceholder': '请输入密码',
    'CodePlaceholder': '请输入验证码'
  },
  // 全部菜单
  menu: {
    'BasicSystemManagement': '基础系统管理',
    'menuManagement': '菜单管理',
    'RightsManagement': '权限管理',
    'accountManagement': '账号管理'
  }
};
