import request from '@/utils/request.js'

// 地区管理
export function getDistrictIndexApi(query) {
  return request({
    url: '/api/manage/district/index',
    method: 'get',
    params: {
      ...query
    }
  })
}
// 获取地区数据
export function getDistrictDataApi() {
  return request({
    url: '/api/manage/district/data',
    method: 'get'
  })
}
// 物流公司
export function getCompanyIndexApi(query) {
  return request({
    url: '/api/manage/company/index',
    method: 'get',
    params: {
      ...query
    }
  })
}
// 物流公司详情
export function getCompanyDetailApi(id) {
  return request({
    url: '/api/manage/company/detail',
    method: 'get',
    params: {
      id
    }
  })
}
// 物流公司编辑
export function getCompanyEditApi(data) {
  return request({
    url: '/api/manage/company/edit',
    method: 'post',
    data
  })
}
// 物流公司新建
export function getCompanyCreateApi(data) {
  return request({
    url: '/api/manage/company/create',
    method: 'post',
    data
  })
}
/* 运费模板 */
export function getDeliveryfeeIndexApi(query) {
  return request({
    url: '/api/manage/deliveryfee/index',
    method: 'get',
    params: {
      ...query
    }
  })
}
/* 创建 */
export function getDeliveryfeeCreateApi(data) {
  return request({
    url: '/api/manage/deliveryfee/create',
    method: 'post',
    data
  })
}
/* 编辑 */
export function getDeliveryfeeEditApi(data) {
  return request({
    url: '/api/manage/deliveryfee/edit',
    method: 'post',
    data
  })
}
/* 查看详情 */
export function getDeliveryfeeDetailApi(id) {
  return request({
    url: '/api/manage/deliveryfee/detail',
    method: 'get',
    params: {
      id
    }
  })
}
/* 获取所有地区 */
export function getDistrictAllApi() {
  return request({
    url: '/api/manage/district/all',
    method: 'get'
  })
}
// 获取快递平台配置信息
export function getExpressConfApi() {
  return request({
    url: '/api/manage/express/conf',
    method: 'get'
  })
}
// 保存
export function postExpressSetConfigApi(data) {
  return request({
    url: '/api/manage/express/setConfig',
    method: 'post',
    data:{
		config:data
	}
  })
}