// en
export default {
  // 登录页面
  login: {
    'BackgroundLogin': 'Background login',
    'logon': 'log on',
    'username': 'username',
    'password': 'password',
    'code': 'code',
    'UserNamePlaceholder': 'Please input your username',
    'PassWordPlaceholder': 'Please input your password',
    'CodePlaceholder': 'Please input your code'
  },
  // 全部菜单
  menu: {
    'BasicSystemManagement': 'Basic System Management',
    'menuManagement': 'menu management',
    'RightsManagement': 'Rights Management',
    'accountManagement': 'account management'
  }
};
