import request from '@/utils/request.js'

// 获取所有数据
export function getAccountIndexApi(username,nickname,pageOption) {
	return request({
		url: '/api/manage/account/index',
		method: 'get',
		params: {
			nickname,
			username,
			page:pageOption.current,
			page_size:pageOption.pageSize
		}
	})
}
// 修改账号的状态
export function accountChangeStatusApi(id,status) {
	return request({
		url: '/api/manage/account/changeStatus',
		method: 'post',
		params: {
			id,status
		}
	})
}
// 查看账号详情
export function accountDetailApi(id) {
	return request({
		url: '/api/manage/account/detail',
		method: 'get',
		params: {
			id
		}
	})
}
// 新建
export function accountCreateApi(data) {
	return request({
		url: '/api/manage/account/create',
		method: 'post',
		params: {
			...data
		}
	})
}
// 查看账号权限
export function accountAuthlistApi(id) {
	return request({
		url: '/api/manage/account/authlist',
		method: 'post',
		params: {
			id
		}
	})
}
// 编辑账号信息
export function accountEditApi(value) {
	return request({
		url: '/api/manage/account/edit',
		method: 'post',
		params: {
			id:value.id,
			username:value.username,
			nickname:value.nickname,
			head_pic_url:value.head_pic_url,
			type:value.type,
			status:value.status
		}
	})
}
// 修改密码
export function accountChangePwdApi(value) {
	return request({
		url: '/api/manage/account/changePwd',
		method: 'post',
		params: {
			id:value.id,
			org_password:value.org_password,
			new_password:value.new_password
		}
	})
}
// 修改权限
export function accountChangeAuthApi(value) {
	return request({
		url: '/api/manage/account/changeAuth',
		method: 'post',
		params: {
			admin_id:value.id,
			role:value.role,
		}
	})
}
// 获取所有数据
export function getRoleIndexApi(name,pageOption) {
	return request({
		url: '/api/manage/role/index',
		method: 'get',
		params: {
			name:name,
			page:pageOption.current,
			page_size:pageOption.pageSize
		}
	})
}
// 创建
export function roleCreateApi(data) {
	return request({
		url: '/api/manage/role/create',
		method: 'post',
		params: {
			...data
		}
	})
}
// 查看
export function roleDetailApi(id) {
	return request({
		url: '/api/manage/role/detail',
		method: 'get',
		params: {
			id
		}
	})
}
// 编辑
export function roleEditApi(data) {
	return request({
		url: '/api/manage/role/edit',
		method: 'post',
		params: {
			...data
		}
	})
}
// 删除
export function roleDeleteApi(id) {
	return request({
		url: '/api/manage/role/delete',
		method: 'get',
		params: {
			id
		}
	})
}
// 获取详情
export function getMenuDetailApi(id) {
	return request({
		url: '/api/manage/menu/detail',
		method: 'get',
		params: {
			id
		}
	})
}
// 删除
export function deleteMenuApi(id) {
	return request({
		url: '/api/manage/menu/delete',
		method: 'get',
		params: {
			id
		}
	})
}
// 创建
export function menuCreateApi(data) {
	let value = JSON.parse(JSON.stringify(data));
	delete value.middleware_selected
	delete value.parent_ids
	return request({
		url: '/api/manage/menu/create',
		method: 'post',
		params: {
			...value
		}
	})
}
// 编辑
export function menuEditApi(data) {
	let value = JSON.parse(JSON.stringify(data));
	delete value.middleware_selected
	delete value.parent_ids
	return request({
		url: '/api/manage/menu/edit',
		method: 'post',
		params: {
			...value
		}
	})
}
// 查看日志
export function getLogListApi(id,pagination,url,method) {
	return request({
		url,
		method,
		params: {
			id,
			page:pagination.current,
			page_size:pagination.pageSize
		}
	})
}
// 获取插件管理所有数据
export function getManagePluginIndexApi(params) {
	return request({
		url: '/api/manage/plugin/index',
		method: 'get',
		params: {
			...params
		}
	})
}
// 插件装/卸
export function getManagePluginEditApi(id,status) {
	return request({
		url: '/api/manage/plugin/edit',
		method: 'post',
		data: {
			id,
			status
		}
	})
}
// 商品配置-猜你喜欢-获取配置信息
export function getProductGetYouLikeApi() {
	return request({
		url: '/api/manage/product/getYouLike',
		method: 'get'
	})
}
// 设置
export function postProductYouLikeApi(data) {
	return request({
		url: '/api/manage/product/youlike',
		method: 'post',
		data: {
			...data
		}
	})
}
// 获取配置列表
export function getPluginAllApi() {
	return request({
		url:'/api/plugin/all',
		method: 'get'
	})
}
// 获取配置项目
export function getWechatminiConfigApi(url) {
	return request({
		url,
		method: 'get'
	})
}
// 获取配置值
export function getWechatminiGetconfigApi(url) {
	return request({
		url,
		method: 'get'
	})
}
// 设置配置值
export function postWechatminiSetconfApi(url,data) {
	return request({
		url,
		method: 'post',
		data: {
			...data
		}
	})
}
// 获取消息列表
export function getMessageListApi() {
	return request({
		url:'/api/manage/message/list',
		method: 'get'
	})
}
// 获取系统支持的消息发送类型
export function getMessageTypesApi() {
	return request({
		url:'/api/manage/message/types',
		method: 'get'
	})
}
// 编辑
export function postMessageEditApi(data) {
	return request({
		url:'/api/manage/message/edit',
		method: 'post',
		data: {
			...data
		}
	})
}
// 获取消息场景详情
export function getMessageGetconfigApi(id) {
	return request({
		url:'/api/manage/message/getconfig',
		method: 'get',
		params:{
			id
		}
	})
}
// 账号修改消息接收人设置
export function postAccountChangeReceiveMsgApi(data) {
	return request({
		url:'/api/manage/account/changeReceiveMsg',
		method: 'post',
		data: {
			...data
		}
	})
}
// 轮播图管理
export function getBannerIndexApi(params) {
	return request({
		url:'/api/manage/banner/index',
		method: 'get',
		params
	})
}
// 编辑
export function postBannerEditApi(data) {
	return request({
		url:'/api/manage/banner/edit',
		method: 'post',
		data: {
			...data
		}
	})
}
// 新增
export function postBannerCreateApi(data) {
	return request({
		url:'/api/manage/banner/create',
		method: 'post',
		data: {
			...data
		}
	})
}
// 详情
export function getBannerDetailApi(id) {
	return request({
		url: '/api/manage/banner/detail',
		method: 'get',
		params: {
			id
		}
	})
}