import request from '@/utils/request.js'

// 公告管理
export function getAnnouncementIndex(query) {
	return request({
		url: '/api/manage/announcement/index',
		method: 'get',
		params: {
			...query
		}
	})
}
// 编辑公告
export function getAnnouncementEditApi(query) {
	return request({
		url: '/api/manage/announcement/edit',
		method: 'post',
		data: {
			...query
		}
	})
}
// 新增公告
export function getAnnouncementCreateApi(query) {
	return request({
		url: '/api/manage/announcement/create',
		method: 'post',
		data: {
			...query
		}
	})
}
// 查看详情
export function getAnnouncementDetailApi(id) {
	return request({
		url: '/api/manage/announcement/detail',
		method: 'get',
		params: {
			id
		}
	})
}
// 文章分类
export function getArticleclassIndexApi(query) {
	return request({
		url: '/api/manage/articleclass/index',
		method: 'get',
		params: {
			...query
		}
	})
}
// 新增文章分类
export function getArticleclassCreateApi(query) {
	return request({
		url: '/api/manage/articleclass/create',
		method: 'post',
		data: {
			...query
		}
	})
}
// 编辑文章分类
export function getArticleclassEditApi(query) {
	return request({
		url: '/api/manage/articleclass/edit',
		method: 'post',
		data: {
			...query
		}
	})
}
// 获取详情
export function getArticleclassDetailApi(id) {
	return request({
		url: '/api/manage/articleclass/detail',
		method: 'get',
		params: {
			id
		}
	})
}
// 文章管理
export function getArticleIndexApi(query) {
	return request({
		url: '/api/manage/article/index',
		method: 'get',
		params: {
			...query
		}
	})
}
// 发布文章
export function getArticlePublishApi(id) {
	return request({
		url: '/api/manage/article/publish',
		method: 'get',
		params: {
			id
		}
	})
}
// 新建文章
export function getArticleCreateApi(query) {
	return request({
		url: '/api/manage/article/create',
		method: 'post',
		data: {
			...query
		}
	})
}
// 查看文章
export function getArticleDetailApi(id) {
	return request({
		url: '/api/manage/article/detail',
		method: 'get',
		params: {
			id
		}
	})
}
// 编辑文章
export function getArticleEditApi(query) {
	return request({
		url: '/api/manage/article/edit',
		method: 'post',
		data: {
			...query
		}
	})
}
// 视频合集管理
export function getvideocollectIndexApi(query) {
	return request({
		url: '/api/manage/videocollect/index',
		method: 'get',
		params: {
			...query
		}
	})
}
// 新增视频合集
export function getVideocollectCreateApi(query) {
	return request({
		url: '/api/manage/videocollect/create',
		method: 'post',
		data: {
			...query
		}
	})
}
// 编辑视频合集
export function getVideocollectEditApi(query) {
	return request({
		url: '/api/manage/videocollect/edit',
		method: 'post',
		data: {
			...query
		}
	})
}
// 获取视频合集详情
export function getVideocollectDetailApi(id) {
	return request({
		url: '/api/manage/videocollect/detail',
		method: 'get',
		params: {
			id
		}
	})
}
// 筛选视频合集
export function getVideocollectSelApi(params) {
	return request({
		url: '/api/manage/videocollect/sel',
		method: 'get',
		params: {
			...params
		}
	})
}
// 帮助管理
export function getVideoIndexApi(query) {
	return request({
		url: '/api/manage/video/index',
		method: 'get',
		params: {
			...query
		}
	})
}
// 新增
export function getVideoCreateApi(query) {
	return request({
		url: '/api/manage/video/create',
		method: 'post',
		data: {
			...query
		}
	})
}
// 详情
export function getVideoDetailApi(id) {
	return request({
		url: '/api/manage/video/detail',
		method: 'get',
		params: {
			id
		}
	})
}
// 编辑
export function getVideoEditApi(query) {
	return request({
		url: '/api/manage/video/edit',
		method: 'post',
		data: {
			...query
		}
	})
}