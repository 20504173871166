import request from '@/utils/request.js'

// 登录方法
export function login(query) {
  return request({
    url: '/api/manage/login',
    method: 'POST',
    data: {
      ...query
    }
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/api/manage/vcode',
    method: 'get',
    timeout: 20000
  })
}
// 获取菜单列表
export function getMenuIndexApi(pid) {
  return request({
    url: '/api/manage/menu/index',
    method: 'get',
	params:{
		pid
	}
  })
}
//下拉框（搜索+分页）
export function productSelectDataApi(url,params) {
	return request({
		url,
		method: 'get',
		params
	})
}
// 删除
export function itemDeleteApi(id,url) {
	return request({
		url,
		method: 'get',
		params: {
			id
		}
	})
}