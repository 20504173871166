import request from '@/utils/request.js'

// 商品标签管理
export function getProducttagIndex(query) {
	return request({
		url: '/api/manage/producttag/index',
		method: 'get',
		params: {
			...query
		}
	})
}
// 创建商品标签
export function producttagCreateApi(query) {
	return request({
		url: '/api/manage/producttag/create',
		method: 'post',
		data: {
			...query
		}
	})
}
// 商品标签详情
export function producttagDetailApi(id) {
	return request({
		url: '/api/manage/producttag/detail',
		method: 'get',
		params: {
			id
		}
	})
}
// 修改
export function producttagEditApi(query) {
	return request({
		url: '/api/manage/producttag/edit',
		method: 'post',
		data: {
			...query
		}
	})
}
// 删除
export function producttagDeleteApi(id) {
	return request({
		url: '/api/manage/producttag/delete',
		method: 'get',
		params: {
			id
		}
	})
}
/* 商品品牌管理 */
export function getProductbrandIndex(query) {
	return request({
		url: '/api/manage/productbrand/index',
		method: 'get',
		params: {
			...query
		}
	})
}
// 创建商品品牌
export function productbrandCreateApi(query) {
	const data = JSON.parse(JSON.stringify(query));
	delete data.logo_urlArry
	return request({
		url: '/api/manage/productbrand/create',
		method: 'post',
		data: {
			...data
		}
	})
}
// 查看详情
export function productbrandDetailApi(id) {
	return request({
		url: '/api/manage/productbrand/detail',
		method: 'get',
		params: {
			id
		}
	})
}
// 修改商品品牌信息
export function productbrandEditApi(query) {
	const data = JSON.parse(JSON.stringify(query));
	delete data.logo_urlArry
	return request({
		url: '/api/manage/productbrand/edit',
		method: 'post',
		data: {
			...data
		}
	})
}
// 删除
export function productbrandDeleteApi(id) {
	return request({
		url: '/api/manage/productbrand/delete',
		method: 'get',
		params: {
			id
		}
	})
}
// 商品服务项目管理
export function productServiceIndex(query) {
	return request({
		url: '/api/manage/productservice/index',
		method: 'get',
		params: {
			...query
		}
	})
}
// 查看
export function productServiceDetailApi(id) {
	return request({
		url: '/api/manage/productservice/detail',
		method: 'get',
		params: {
			id
		}
	})
}
// 删除
export function productServiceDeleteApi(id) {
	return request({
		url: '/api/manage/productservice/delete',
		method: 'get',
		params: {
			id
		}
	})
}
// 创建
export function productServiceCreateApi(query) {
	const data = JSON.parse(JSON.stringify(query));
	delete data.logo_urlArry
	return request({
		url: '/api/manage/productservice/create',
		method: 'post',
		data: {
			...data
		}
	})
}
// 编辑
export function productServiceEditApi(query) {
	const data = JSON.parse(JSON.stringify(query));
	delete data.logo_urlArry
	return request({
		url: '/api/manage/productservice/edit',
		method: 'post',
		data: {
			...data
		}
	})
}
// 商品供应商管理
export function productSupplierIndex(query) {
	return request({
		url: '/api/manage/productsupplier/index',
		method: 'get',
		params: {
			...query
		}
	})
}
// 查看
export function productSupplierDetailApi(id) {
	return request({
		url: '/api/manage/productsupplier/detail',
		method: 'get',
		params: {
			id
		}
	})
}
// 删除
export function productSupplierDeleteApi(id) {
	return request({
		url: '/api/manage/productsupplier/delete',
		method: 'get',
		params: {
			id
		}
	})
}
// 创建
export function productSupplierCreateApi(query) {
	return request({
		url: '/api/manage/productsupplier/create',
		method: 'post',
		data: {
			...query
		}
	})
}
// 编辑
export function productSupplierEditApi(query) {
	return request({
		url: '/api/manage/productsupplier/edit',
		method: 'post',
		data: {
			...query
		}
	})
}
// 商品参数模板管理
export function productParamTemplateIndex(query) {
	return request({
		url: '/api/manage/productparamtemplate/index',
		method: 'get',
		params: {
			...query
		}
	})
}
// 
export function productParamTemplateDetailApi(id) {
	return request({
		url: '/api/manage/productparamtemplate/detail',
		method: 'get',
		params: {
			id
		}
	})
}
// 删除
export function productParamTemplateDeleteApi(id) {
	return request({
		url: '/api/manage/productparamtemplate/delete',
		method: 'get',
		params: {
			id
		}
	})
}
// 创建
export function productParamTemplateCreateApi(query) {
	return request({
		url: '/api/manage/productparamtemplate/create',
		method: 'post',
		data: {
			...query
		}
	})
}
// 编辑
export function productParamTemplateEditApi(query) {
	return request({
		url: '/api/manage/productsupplier/edit',
		method: 'post',
		data: {
			...query
		}
	})
}
/*  */
export function productItemIndex(query) {
	return request({
		url: '/api/manage/productitem/index',
		method: 'get',
		params: {
			...query
		}
	})
}
/* 删除 */
export function productItemDeleteApi(id) {
	return request({
		url: '/api/manage/productitem/delete',
		method: 'get',
		params: {
			id
		}
	})
}
/* 详情 */
export function productItemDetailApi(id) {
	return request({
		url: '/api/manage/productitem/detail',
		method: 'get',
		params: {
			id
		}
	})
}
/* 创建 */
export function productItemCreateApi(query) {
	return request({
		url: '/api/manage/productitem/create',
		method: 'post',
		data: {
			...query
		}
	})
}
/* 编辑 */
export function productItemEditApi(query) {
	return request({
		url: '/api/manage/productitem/edit',
		method: 'post',
		data: {
			...query
		}
	})
}
/* 商品分类管理 */
export function productClassIndexApi(query) {
	return request({
		url: '/api/manage/productclass/index',
		method: 'get',
		params: {
			...query
		}
	})
}
/* 查看 */
export function getProductClassDetailApi(id) {
	return request({
		url: '/api/manage/productclass/detail',
		method: 'get',
		params: {
			id
		}
	})
}
/* 创建 */
export function productClassCreateApi(query) {
	const data = JSON.parse(JSON.stringify(query));
	delete data.pic_urlArry;
	delete data.class_iconArry;
	delete data.ad_linkArray;
	return request({
		url: '/api/manage/productclass/create',
		method: 'post',
		data: {
			...data
		}
	})
}
/* 编辑 */
export function productClassEditApi(query) {
	const data = JSON.parse(JSON.stringify(query));
	delete data.pic_urlArry;
	delete data.class_iconArry;
	delete data.ad_linkArray;
	return request({
		url: '/api/manage/productclass/edit',
		method: 'post',
		data: {
			...data
		}
	})
}
/* 删除 */
export function productClassDeleteApi(id) {
	return request({
		url: '/api/manage/productclass/delete',
		method: 'get',
		params: {
			id
		}
	})
}
/* 商品参数详情---分页 */
export function productParamIndex(query) {
	return request({
		url: '/api/manage/productparam/index',
		method: 'get',
		params: {
			...query
		}
	})
}
/* 商品参数详情---所有 */
export function productParamAll(query) {
	return request({
		url: '/api/manage/productparam/all',
		method: 'get',
		params: {
			...query
		}
	})
}
/* 查看 */
export function getProductParamDetailApi(id) {
	return request({
		url: '/api/manage/productparam/detail',
		method: 'get',
		params: {
			id
		}
	})
}
/* 编辑 */
export function productParamEditApi(query) {
	const data = JSON.parse(JSON.stringify(query));
	return request({
		url: '/api/manage/productparam/edit',
		method: 'post',
		data: {
			...data
		}
	})
}
/* 添加 */
export function productParamCreateApi(query) {
	const data = JSON.parse(JSON.stringify(query));
	return request({
		url: '/api/manage/productparam/create',
		method: 'post',
		data: {
			...data
		}
	})
}
/* 删除 */
export function productParamDeleteApi(id) {
	return request({
		url: '/api/manage/productparam/delete',
		method: 'get',
		params: {
			id
		}
	})
}
/* 商品列表 */
export function productIndexApi(query) {
	return request({
		url: '/api/manage/product/index',
		method: 'get',
		params: {
			...query
		}
	})
}
/* 商品下架 */
export function productDownApi(id) {
	return request({
		url: '/api/manage/product/down',
		method: 'get',
		params: {
			id
		}
	})
}
// 批量上架 
export function productBatchupApi(ids) {
	return request({
		url: '/api/manage/product/batchup',
		method: 'get',
		params: {
			ids
		}
	})
}
// 批量下架 
export function productBatchdownApi(ids) {
	return request({
		url: '/api/manage/product/batchdown',
		method: 'get',
		params: {
			ids
		}
	})
}
// 批量下架 
export function productBatchdiscardApi(ids) {
	return request({
		url: '/api/manage/product/batchdiscard',
		method: 'get',
		params: {
			ids
		}
	})
}
//批量设置
export function productBatchsetApi(data) {
	return request({
		url: '/api/manage/product/batchset',
		method: 'post',
		data
	})
}
/* 商品上架 */
export function productUpApi(id) {
	return request({
		url: '/api/manage/product/up',
		method: 'get',
		params: {
			id
		}
	})
}
/* 修改商品排序 */
export function productSortApi(id,sort) {
	return request({
		url: '/api/manage/product/sort',
		method: 'post',
		data: {
			id,
			sort
		}
	})
}
/* 复制商品 */
export function productCopyApi(id) {
	return request({
		url: '/api/manage/product/copy',
		method: 'post',
		data: {
			id
		}
	})
}
/* 删除---加入回收站 */
export function productDiscardApi(id) {
	return request({
		url: '/api/manage/product/discard',
		method: 'get',
		params: {
			id
		}
	})
}
// 恢复 ---在回收站中恢复(单个)
export function productRecoverApi(id) {
	return request({
		url: '/api/manage/product/recover',
		method: 'get',
		params: {
			id
		}
	})
}
// 批量
export function productBatchrecoverApi(ids) {
	return request({
		url: '/api/manage/product/batchrecover',
		method: 'get',
		params: {
			ids
		}
	})
}
// 删除----在回收站中删除(单个)
export function productDeleteApi(id) {
	return request({
		url: '/api/manage/product/delete',
		method: 'get',
		params: {
			id
		}
	})
}
/* 商品分类筛选 */
export function productClassSelectApi(params) {
	return request({
		url: '/api/manage/productclass/select',
		method: 'get',
		params
	})
}
/* 查看商品详情 */
export function getProductDetailApi(params) {
	return request({
		url: '/api/manage/product/detail',
		method: 'get',
		params
	})
}
/* 添加商品 */
export function productCreateApi(query) {
	const data = JSON.parse(JSON.stringify(query));
	return request({
		url: '/api/manage/product/create',
		method: 'post',
		data: {
			...data
		}
	})
}
/* 编辑商品 */
export function productEditApi(query) {
	const data = JSON.parse(JSON.stringify(query));
	return request({
		url: '/api/manage/product/edit',
		method: 'post',
		data: {
			...data
		}
	})
}
/* 添加礼盒商品 */
export function productCreateGiftBoxApi(query) {
	const data = JSON.parse(JSON.stringify(query));
	return request({
		url: '/api/manage/product/createGiftBox',
		method: 'post',
		data: {
			...data
		}
	})
}
// 编辑礼盒
export function productEditGiftBoxApi(query) {
	const data = JSON.parse(JSON.stringify(query));
	return request({
		url: '/api/manage/product/editGiftBox',
		method: 'post',
		data: {
			...data
		}
	})
}
/* 卡券管理 */
export function getGiftcardIndexApi(params) {
	return request({
		url: '/api/manage/giftcard/index',
		method: 'get',
		params
	})
}
/* 生成卡券 */
export function batchCreateCardApi(data) {
	return request({
		url: '/api/manage/product/batchCreateCard',
		method: 'post',
		data: {
			...data
		}
	})
}