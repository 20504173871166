import {
	createRouter,
	createWebHistory
} from 'vue-router'
import RouteAddition from './DynamicRouting.js'
import NProgress from "nprogress"; // 导入 nprogress模块
import "nprogress/nprogress.css"; // 导入样式，否则看不到效果、
import {
	message
} from 'ant-design-vue';

NProgress.configure({
	showSpinner: false
}); // 显示右上角螺旋加载提示

NProgress.configure({
	easing: 'ease', // 动画的缓动函数（easing function）。这里设为 ease，表默认的缓动函数。   
	speed: 1000, // 递增进度条的速度;单位是毫秒    
	showSpinner: false, // 是否显示加载ico;是否在显示加载图标   
	trickleSpeed: 200, // 自动递增间隔;这里表示每间隔 200 毫秒自动递增一次进度   
	minimum: 0.3 // 初始化时的最小百分比
})


const routes = [{
		path: '/',
		name: 'Login',
		meta: {
			title: '登录页'
		},
		component: () => import('@/views/Login/index.vue')
	},
	{
		path: '/DisplayPage',
		name: 'DisplayPage',
		component: () => import('@/views/DisplayPage/index.vue'),
		redirect: '/Home',
		children: [{
				path: '/Home',
				name: 'Home',
				meta: {
					title: '首页'
				},
				component: () => import('@/views/Home/index.vue')
			},
			{
				path: '/404',
				name: '404',
				meta: {
					title: '404页面'
				},
				component: () => import('@/views/error/404.vue')
			},
			{
				path: '/401',
				name: '401',
				meta: {
					title: '401页面'
				},
				component: () => import('@/views/error/401.vue')
			},
		]
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	// history: createWebHashHistory(), // 使用 hash 模式
	routes
})


let token = localStorage.getItem('token')
if (token) {
	try{
		RouteAddition(router);
	}catch (err){
		
	}
}
router.beforeEach((to, from, next) => {
	NProgress.start(); //开启进度条
	const token = localStorage.getItem('token')
	if (to.path == '/') { // 前往登录页
		next()
	} else {
		// 前往其他页面，判断是否有token
		if (token && token != '') { // 有token
			RouteAddition(router)
			next()
		} else { // 没有token
			message.error('请先登录').then(()=>{
				next('/')
			})
			
		}
	}
});


// 后置守卫
router.afterEach((to, from) => {
	document.title = to.meta.title || "管理系统";
	NProgress.done() //完成进度条
})

export default router
