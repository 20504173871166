<template>
	<a-popconfirm title="确定要删除吗?" ok-text="确定" cancel-text="取消" @confirm="confirmFn()">
		<a-tag :bordered="false" color="red">删除</a-tag>
	</a-popconfirm>
	<!-- <a-tag :bordered="false" color="red" @click="deleteFn()">删除</a-tag> -->
</template>

<script setup>
	import {
		inject,
		ref
	} from 'vue'
	import {
		Modal
	} from 'ant-design-vue';
	import {
		itemDeleteApi
	} from '@/api/index'
	const props = defineProps({
		id: Number,
		url: String,
	})
	const emit = defineEmits(['getDataListFn'])
	/* 删除 */
	var msg = inject('message')
	const confirmFn = async() => {
		const res = await itemDeleteApi(props.id,props.url);
		msg.success('删除成功').then(() => {
			emit('getDataListFn')
		});
	}
</script>

<style>
</style>
