import request from '@/utils/request.js'

// 获取详细内容
export function getSystemDetail(query) {
	return request({
		url: '/api/manage/sysConfig/detail',
		method: 'get',
		params: query
	})
}

// 查询系统设置列表
export function listSystem(query) {
	return request({
		url: '/api/manage/sysConfig/group',
		method: 'get',
		params: query
	})
}

export function addSystem(data) {
	return request({
		url: '/api/manage/sysConfig/create',
		method: 'post',
		data
	})
}

export function editSystem(data) {
	return request({
		url: '/api/manage/sysConfig/edit',
		method: 'post',
		data
	})
}
// 
export function changeSystem(data) {
	return request({
		url: '/api/manage/sysConfig/batchGroupEdit',
		method: 'post',
		data
	})
}
export function delSystem(obj) {
	return request({
		url: '/api/manage/sysConfig/changeStatus',
		method: 'POST',
		data: {
			...obj
		}
	})
}


// 批量设置值
export function setSystem(data) {
	return request({
		url: '/api/manage/sysConfig/batchGroupEdit',
		method: 'post',
		data
	})
}
// 图片上传
export function uploadImg(data) {
	const requestData={
		url: '/api/manage/upload',
		method: 'post',
		data:{
			...data,
			is_sys:1
		}
	}
	console.log("requestData",requestData)
	return request(requestData)
}

export function EdituploadImg(data) {
	const requestData={
		url: '/api/manage/upload',
		method: 'post',
		data:{
			// ...data,
			file:data,
			type:1,
			is_sys:1
		}
	}
	console.log("requestData",requestData)
	return request(requestData)
}
