<template>
	<a-config-provider :locale="zhCN">
		<router-view />
	</a-config-provider>
</template>

<script setup>
	import CustomTheme from "@/pinia/theme/index.js";
	import zhCN from "ant-design-vue/es/locale/zh_CN";
	// import RouteAddition from '@/router/DynamicRouting.js'
	import {
		useRouter
	} from "vue-router";
	const app = CustomTheme();
	// console.log("----->app.vue")
	// const router = useRouter()
	// RouteAddition(router);
</script>

<style lang="scss">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}

	.titTip {
		display: inline-bolck;
		font-size: 12px;
		line-height: 24px;
		font-weight: 400;
		color: #999999;
	}

	.ant-tag {
		cursor: pointer;
	}

	// #nprogress .bar {
	//   background: blue !important;    //这里可以随便写颜色
	// }

	// ::-webkit-scrollbar {
	// 	width: 0 !important;
	// }

	// ::-webkit-scrollbar {
	// 	width: 0 !important;
	// 	height: 0;
	// }
	::-webkit-scrollbar {
		/*滚动条整体样式*/
		width: 5px;
		/*高宽分别对应横竖滚动条的尺寸*/
		height: 5px;
	}

	::-webkit-scrollbar-thumb {
		/*滚动条里面小方块*/
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		background: #d8d8d8;
	}

	::-webkit-scrollbar-track {
		/*滚动条里面轨道*/
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
		border-radius: 10px;
		background: #ffffff;
	}

	.ant-table-tbody tr:nth-child(2n+1) {
		background-color: #fafafa;
	}

	.optionsBoxP {
		cursor: pointer;
	}
</style>
